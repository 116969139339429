"use client";

import { CarModel, HomePage as HomePageData } from "@/api";
import { linksConfig } from "@/app/config/linksConfig";
import { useIsDesktop } from "@/common/MediaQueries";
import { scrollToSection } from "@/common/scrollToSection";
import { useHash } from "@/common/useHash";
import { FAQ } from "@/components/Layout/Home/FAQ/FAQ";
import NextLink from "@/components/shared/NextLink";
import { homeStore, inventoryStore } from "@/store";
import colors from "@/theme/colors";
import theme from "@/theme/theme";
import styled, { CSSObject } from "@emotion/styled";
import { Box, Button, Typography, css } from "@mui/material";
import { sendGTMEvent } from "@next/third-parties/google";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import smoothscroll from "smoothscroll-polyfill";
import { A11y, Keyboard, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { PickCar } from "../../shared/Pick";
import { Video } from "../../shared/Video";
import { Contact } from "./Contact/Contact";
import { DescriptionFrame } from "./Description/DescriptionFrame";
import { Recomended } from "./Recomended/Recomended";

const HomeWarpper = styled("div", {
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .grecaptcha-badge {
    display: ${(p) => (p.isVisible ? "block" : "none")} !important;
    z-index: 1;
  }
`;

const VideoOrImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 720px;
  ${theme.breakpoints.down("sm")} {
    height: 600px;
  }
  position: relative;
  top: 0;
  z-index: -1;
`;

const Screen = styled.div`
  background: linear-gradient(
    rgba(255, 255, 255, 0.25) 0,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 1) 75%
  );

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 0;
  align-items: center;
  justify-content: center;
  top: 100;
  ${theme.breakpoints.down("sm")} {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const Slogan = styled(Typography)`
  position: absolute;
  animation: bounce 0.2s cubic-bezier(0.11, 0.77, 0.68, 1.32) forwards;
  opacity: 0;
  color: ${colors.blue};
  font-weight: 500;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    font-size: 24px;
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(120%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SwipperWrapper = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 100%;
  height: auto;
  max-width: 649px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${theme.breakpoints.down("sm")} {
    max-width: 240px;
  }
  ${theme.breakpoints.up("md")} {
    height: 100%;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 93% !important;
  object-fit: contain;

  ${theme.breakpoints.up("md")} {
    height: 350px !important;
  }
`;

const StyledSwiperJs = styled(Swiper)`
  height: 350px;
  width: 100%;
  ${theme.breakpoints.up("md")} {
    height: 450px !important;
  }
  & .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.47, 0.5, 0.57, 1.19);
  }
  ${theme.breakpoints.down("sm")} {
    height: 200px;
  }
  .swiper-backface-hidden,
  .swiper-slide {
    display: flex !important;
  }
  .swiper-slide,
  .swiper-slide-duplicate {
    opacity: 0;
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.3s ease;
    transition-property: all !important;
    transform: scale(0.65) !important;
    ${theme.breakpoints.down("sm")} {
      transform: scale(0.85) !important;
    }
  }
  .swiper-slide-visible,
  .swiper-slide-duplicate-visible {
    opacity: 1;
    ${(p) =>
      p.loop &&
      css`
        &:first-child {
          transform: scale(0.65) translate(5px, 0) !important;
          ${theme.breakpoints.down("sm")} {
            transform: scale(0.85) !important;
          }
        }
      `}
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1) !important;
  }
  .swiper-slide-next,
  .swiper-slide-duplicate-next {
    opacity: 0.5;
    transform: scale(0.65) translate(-10px, 0) !important;
    ${theme.breakpoints.down("sm")} {
      transform: scale(0.85) !important;
    }
  }
  .swiper-slide-prev,
  .swiper-slide-duplicate-prev {
    opacity: 0.5;
    transform: scale(0.65) translate(10px, 0) !important;
    ${theme.breakpoints.down("sm")} {
      transform: scale(0.85) !important;
    }
  }
`;
const StyledSuspendedNextLink = styled(NextLink)`
  text-decoration: none;
  color: #262626;
  &:visited {
    color: #262626;
  }
`;
const PouseAndPlay = styled(Button)(
  () =>
    ({
      position: "absolute",
      inset: "150px auto auto 120px",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        top: "100px",
        left: "0",
        inset: "100px auto auto 0",
      },
    }) as CSSObject
);
const ActionButtons = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible?: boolean }>(({ isVisible }) => ({
  opacity: isVisible ? 0 : 1,
  visibility: isVisible ? "hidden" : "visible",
  transition: "opacity 0.3s ease, visibility 0.3s ease",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "sticky",
  zIndex: 2,
  margin: 5,
  borderRadius: 0,
  backgroundColor: colors.white,
  padding: 16,
  gap: 8,
  boxShadow: "0 1px 20px rgba(0, 36, 78, 0.3)",
  top: "calc(100vh - 100px)",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: 360,
    position: "fixed",
    top: "calc(100vh - 200px)",
  },
}));

const AbsuloteWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 150px;
  margin-bottom: 50px;
`;

const ButtonWrapper = styled(Button)(
  ({
    grow,
    max,
    widthsize,
    colorbg,
  }: {
    grow: number;
    max: number;
    colorbg?: string;
    widthsize?: number;
  }) => ({
    width: 194,
    height: 50,
    borderRadius: 0,
    backgroundColor: colorbg ? colorbg : "",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flex: 1,
      flexGrow: grow,
      maxWidth: max,
      width: widthsize,
    },
  })
);

const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.up("md")} {
    gap: 16px;
  }
`;

const NavigationRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 16px 120px 0px 16px;
  ${theme.breakpoints.down("md")} {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  justify-content: space-between;
`;

const NavigationLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 22px 43px 32px;

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledLink = styled(NextLink)`
  text-decoration: none;
  border-bottom: 1px solid black;
  color: inherit;
  a:active,
  a:visited {
    color: black;
  }
`;

const time = 3000;

type HomePageProps = {
  modelsData: CarModel[];
  homeData: HomePageData;
};
const StyledH2 = styled(Typography)`
  font-weight: 400;
  p {
    padding: 0;
    margin: 0;
    font-size: 24px;
  }
`;
const HomePage = ({ modelsData, homeData }: HomePageProps) => {
  const [currentSlogan, setCurrentSlogan] = useState(0);
  const [sloganPoused, setSloganPoused] = useState("");
  const [pouse, setPouse] = useState(false);
  const [mainBannerTitle, setMainBannerTitle] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const hash = useHash();
  const { ref, inView } = useInView({
    threshold: 0.3,
  });
  homeStore({ ...homeStore(), ...homeData });
  inventoryStore({ ...inventoryStore(), cars: modelsData });

  const slogans = homeData?.mainBanner?.alternateText;

  const pauseAndPlay = () => {
    sendGTMEvent({
      action: "click",
      event: "play_top_video_clicks",
    });
    setSloganPoused(slogans[currentSlogan]);
    setPouse(!pouse);
  };

  useEffect(() => {
    if (pouse) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [pouse]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlogan((prevSlogan) => (prevSlogan + 1) % slogans.length);
    }, time);
    return () => clearInterval(interval);
  }, [slogans]);

  useEffect(() => {
    setMainBannerTitle(homeData.mainBanner.title);
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const section = hash.split("?")[0].replace("#", "");

    if (section && section === "faq") {
      scrollToSection(section);
    }
  }, [hash]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const isDesktop = useIsDesktop();

  const isVideo = (url: string): boolean => {
    return url.includes(".mp4") || url.includes("youtube");
  };
  return (
    <HomeWarpper isVisible={inView}>
      {isVideo(homeData?.mainBanner?.backgroundImage?.imageUrl) ? (
        <>
          <PouseAndPlay variant="text" onClick={pauseAndPlay}>
            <Image
              src={pouse ? "assets/pause.svg" : "assets/play.svg"}
              alt="play"
              width={30}
              height={30}
              priority
            />
          </PouseAndPlay>
          <VideoOrImageWrapper>
            <Video
              ref={videoRef}
              src={homeData?.mainBanner?.backgroundImage?.imageUrl}
            />
            <Screen />
          </VideoOrImageWrapper>
        </>
      ) : (
        <VideoOrImageWrapper>
          <Image
            src={homeData?.mainBanner?.backgroundImage?.imageUrl}
            alt=""
            fill
            style={{ objectFit: "cover" }}
            priority
          />
          <Screen />
        </VideoOrImageWrapper>
      )}

      <AbsuloteWrapper>
        <HeroWrapper>
          <StyledH2
            dangerouslySetInnerHTML={{ __html: mainBannerTitle }}
            role="heading"
            aria-level={1}
          />

          <Box
            mt={2}
            justifyContent={"center"}
            display={"flex"}
            width={!isDesktop ? "100%" : "auto"}
          >
            {slogans.map((slogan, index) => (
              <Slogan
                key={index}
                variant="h4"
                style={{
                  display: pouse
                    ? sloganPoused
                    : currentSlogan === index
                      ? "block"
                      : "none",
                }}
              >
                {slogan}
              </Slogan>
            ))}
          </Box>
          <Typography variant="h2" mt={12} mb={0} fontWeight={400}>
            {homeData.mainBanner.topModelsTitle}
          </Typography>
        </HeroWrapper>
        <SwipperWrapper>
          <StyledSwiperJs
            modules={[A11y, Keyboard, Parallax]}
            parallax
            slidesPerView={"auto"}
            allowTouchMove
            grabCursor
            slideToClickedSlide
            centeredSlides
            resizeObserver
            loop
            keyboard={{
              enabled: true,
            }}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
          >
            {homeData.mainBanner.topModels.mainBannerTopModels.map(
              (image, index) => {
                if (activeIndex === index) {
                  return (
                    <StyledSwiperSlide key={index}>
                      <StyledSuspendedNextLink
                        passHref
                        href={`/product/${homeData.mainBanner.topModels.mainBannerTopModels[index].carModelId}`}
                      >
                        <StyledImage
                          src={image.image.imageModel.imageUrl}
                          alt=""
                          fill
                          priority={index <= 1}
                        />
                        <TitlesWrapper>
                          <Typography
                            sx={{ textAlign: "center" }}
                            fontSize={isDesktop ? 24 : 14}
                            fontWeight={400}
                            variant="h3"
                          >
                            {image.title}
                          </Typography>
                          <Typography
                            fontSize={isDesktop ? 18 : 12}
                            fontWeight={isDesktop ? 400 : 500}
                          >
                            {image.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "ILS",
                              maximumFractionDigits: 0,
                            })}
                          </Typography>
                        </TitlesWrapper>
                      </StyledSuspendedNextLink>
                    </StyledSwiperSlide>
                  );
                } else {
                  return (
                    <StyledSwiperSlide
                      onClick={() => {
                        setActiveIndex(index);
                      }}
                      key={index}
                    >
                      <StyledImage
                        src={image.image.imageModel.imageUrl}
                        alt=""
                        fill
                        priority={
                          index === 1 ||
                          index ===
                            homeData.mainBanner.topModels.mainBannerTopModels
                              .length -
                              1
                        }
                      />
                      <TitlesWrapper>
                        <Typography
                          sx={{ textAlign: "center" }}
                          fontSize={isDesktop ? 24 : 14}
                          fontWeight={400}
                          variant="h3"
                        >
                          {image.title}
                        </Typography>
                        <Typography
                          fontSize={isDesktop ? 18 : 12}
                          fontWeight={isDesktop ? 400 : 500}
                        >
                          {image.price.toLocaleString("en-US", {
                            style: "currency",
                            currency: "ILS",
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                      </TitlesWrapper>
                    </StyledSwiperSlide>
                  );
                }
              }
            )}
          </StyledSwiperJs>
        </SwipperWrapper>
      </AbsuloteWrapper>
      <ActionButtons isVisible={inView}>
        <NextLink legacyBehavior passHref href={linksConfig.inventory}>
          <ButtonWrapper
            onClick={() => {
              sendGTMEvent({
                action: "click",
                event: "find_a_car_for_me_clicks",
              });
            }}
            max={200}
            widthsize={194}
            grow={2}
            variant="contained"
            color="primary"
          >
            תמצאו לי רכב
          </ButtonWrapper>
        </NextLink>
        <NextLink href={linksConfig.contact} passHref legacyBehavior>
          <ButtonWrapper
            onClick={() =>
              sendGTMEvent({
                action: "click",
                event: "hp_contact_clicks",
              })
            }
            max={170}
            grow={1}
            variant="contained"
            color="primary"
            colorbg="rgba(77, 77, 77, 1)"
          >
            יצירת קשר
          </ButtonWrapper>
        </NextLink>
      </ActionButtons>
      <PickCar />
      <DescriptionFrame data={homeData.advantages} />

      <Recomended mobile={isDesktop ? false : true} />

      <FAQ />
      <Contact contactRef={ref} />
      <NavigationRow
        style={{ flexDirection: "row", borderTop: "1px solid #F2F2F2" }}
      >
        <NavigationLinks>
          <Typography fontSize={14} component={"h2"}>
            בחר את ה-BMW שלך
          </Typography>
          <Typography fontWeight={800} fontSize={14}>
            <StyledLink href={linksConfig.inventory}>כל הדגמים</StyledLink>
          </Typography>
        </NavigationLinks>
      </NavigationRow>
    </HomeWarpper>
  );
};

export default HomePage;
